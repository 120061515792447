.list-transition-enter,
.list-transition-appear {
  /*We give the list the initial dimension of the list button*/
opacity: 0;
max-height: 0;

}
/* This is where we can add the transition*/
.list-transition-enter-active,
.list-transition-appear-active {
  transition: opacity 300ms;
  opacity: 1;

}

/* This fires as soon as the this.state.showList is false */
.list-transition-exit {
opacity: 1;
}
/* fires as element leaves the DOM*/
.list-transition-exit-active {
  transition: all 300ms;
  opacity: 0;
  }