.modal {
    transition: opacity 0.25s ease;
  }
  body.modal-active {
    overflow-x: hidden;
    overflow-y: visible !important;
  }
.react-datepicker-wrapper, .react-datepicker__input-container {
  display: block !important;
}

input[type="text"]:disabled {
  background-color: #fcfff7;
}

button[type="button"]:disabled {
  display:none;
}